import React from "react";
import { Auth } from "aws-amplify";
//import Moment from 'react-moment';
import config from "../config"

class Clock extends React.Component {
  constructor(props) {
    super(props);
    var now = new Date().getTime();
    this.state = {date: now, activity: parseInt(sessionStorage['last_activity_time']), timeout: now + config.bank.TIMEOUT /*1 * 60 * 1000*/};
  }

  componentDidMount() {
    this.timerID = setInterval(
      () => this.tick(),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  async tick() {
    var now = new Date().getTime();
    var lastActivity = parseInt(sessionStorage['last_activity_time']);

    this.setState({
      date: now,
      activity: lastActivity,
      timeout: (isNaN(lastActivity)? now: lastActivity) + config.bank.TIMEOUT//1 * 60 * 1000
    });

    if (parseInt(this.state.timeout) < parseInt(now))
    {
      this.setState({
        activity: ""
      });
      sessionStorage['last_activity_time'] = "";
      Auth.signOut();
      var hrefStr = window.location.href;
      window.location.replace(hrefStr.substring(0, hrefStr.lastIndexOf("/")));
    }
  }

  render() {
    return (
      <div>
{/*
        <h1>.</h1>
        <h5>URL {window.location.href}</h5>
        <h5>Last Activity {sessionStorage['last_activity_time']}</h5>
        <h5>CT <Moment format='hh:mm:ss A' date={new Date().setTime(this.state.date)}/></h5>
        <h5>LA <Moment format='hh:mm:ss A' date={new Date().setTime(parseInt(this.state.activity))}/></h5>
        <h5>TO <Moment format='hh:mm:ss A' date={new Date().setTime(this.state.timeout)}/></h5>
*/}
      </div>
    );
  }
}

export default Clock;
